import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAppointmentAssist,
  openAppointmentsGrid,
} from "../../../../actions/accidentDashboard";
import { getFormProfessionals, postForm } from "../../../../actions/forms";
import { openNewForm } from "../../../../actions/formsState";
import {
  convertDateTimeToNet,
  validateDateRange,
} from "../../../../utils/datetimeUtils";
import CircularLoading from "../../../commons/loadingData/circularLoading";
import { useStyles } from "../formStyle";
import Iet from "./iet";

const initialIetModel = {
  nombreTipoFormulario: null,
  fechaAtencion: null,
  nroFormularioFPA: null,
  fechaHoraNuevoControl: null,
  diagnosticoOMS: null,
  diagnostico: "",
  naturalezaLesion: null,
  zonaAfectada: null,
  observaciones: "",
  profesionalApellidoNombre: null,
  profesionalMnMp: "",
  especialidadProfesional: "",
  especialidadInforme: "",
  informacionSensible: false,
  fechaProbableDeAlta: null,
  fechaProrrogaAlta: null,
  representa: "",
};

const appointmentModel = {
  fechaHora: null,
  especialidad: null,
  profesional: null,
  profesionalApellidoNombre: null,
  matricula: "",
  observacion: "",
  traslado: false,
  fromDB: false,
  idTurnoSiniestro: null,
};

const IetContainer = ({
  handleClose,
  isView,
  setLoaded,
  handleOpenCancelDialog,
  selectedDocument,
  idSiniestro,
  idArt,
  disabled,
  postFormCallback,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const professionalSpecialties = useSelector(
    (state) => state.forms.professionalSpecialties,
  );
  const saveForm = useSelector((state) => state.forms.saveForm);
  const model = useSelector((state) => state.forms.model);
  const form = useSelector((state) => state.forms.form);
  const diagnosis = useSelector((state) => state.forms.diagnosis);
  const professionals = useSelector((state) => state.forms.professionals);
  const documentation = useSelector(
    (state) => state.accidentDashboard.availableDocumentsToAdd,
  );
  const calendar = useSelector(
    (state) => state.accidentDashboard.appointmentsCalendar,
  );
  const appointmentsPostForm = useSelector(
    (state) => state.accidentDashboard.appointmentsPostForm,
  );
  const [ietModel, setIetModel] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [files, setFiles] = useState([]);
  const [openAppointment, setOpenAppointment] = useState(false);
  const [appointment, setAppointment] = useState(appointmentModel);

  const disabledForm =
    disabled !== null
      ? disabled
      : form.data.roles
      ? !form.data.roles?.editar
      : true;
  const isLoading =
    model.isFetching ||
    saveForm.isFetching ||
    form.isFetching ||
    diagnosis.isFetching ||
    professionals.isFetching ||
    calendar.isFetching ||
    !ietModel ||
    !formValues;

  useEffect(() => {
    if (isView && !form.isFetching) {
      setFiles(form.data.form.adjuntos);
      if (form.data.form.turno && form.data.form.turno.matricula) {
        setAppointment(form.data.form.turno);
      }
    }
  }, [form.isFetching]);

  useEffect(() => {
    if (!isView) {
      if (!model.isFetching) {
        setIetModel(model.data);
        setFormValues(() => ({
          ...initialIetModel,
          fechaAtencion: new Date(),
          diagnosticoOMS: model.data.fields.diagnosticoOMS
            ? model.data.fields.diagnosticoOMS.options
            : initialIetModel.diagnosticoOMS,
          diagnostico: model.data.fields.diagnostico.options
            ? model.data.fields.diagnostico.options
            : initialIetModel.diagnostico,
          naturalezaLesion: model.data.fields.naturalezaLesion
            ? model.data.fields.naturalezaLesion.options
            : initialIetModel.naturalezaLesion,
          zonaAfectada: model.data.fields.zonaAfectada
            ? model.data.fields.zonaAfectada.options
            : initialIetModel.zonaAfectada,
          profesionalApellidoNombre: model.data.fields.profesionalApellidoNombre
            .options
            ? model.data.fields.profesionalApellidoNombre.options
            : initialIetModel.profesionalApellidoNombre,
          nombreTipoFormulario:
            model.data.fields.tiposFormulario.options.length === 1
              ? model.data.fields.tiposFormulario.options[0].nombre
              : initialIetModel.nombreTipoFormulario,
          especialidadInforme: model.data.fields?.especialidadInforme.options
            ? model.data.fields?.especialidadInforme.options
            : initialIetModel.especialidadInforme,
          informacionSensible: model.data.fields?.informacionSensible.options
            ? model.data.fields?.informacionSensible.options
            : initialIetModel.informacionSensible,
          fechaProbableDeAlta: model.data.fields?.fechaProbableDeAlta?.options
            ? model.data.fields?.fechaProbableDeAlta?.options
            : initialIetModel.fechaProbableDeAlta,
        }));
      }
    } else if (!form.isFetching) {
      setIetModel(form.data.model);
      setFormValues(form.data.form);
    }
  }, [model.isFetching, form.isFetching, isView]);

  useEffect(() => {
    if (!isLoading) {
      setLoaded(true);
    }
  }, [isLoading]);

  const handleSubmit = (form) => {
    if (!isView) {
      var appointmentsInRange = calendar.data.appointments?.filter(
        (d) =>
          validateDateRange(d.fechaHora, form, null, model) &&
          (d.estado === "Programados" || d.estado === "No informados"),
      );
    }
    if (
      appointmentsInRange &&
      !appointmentsPostForm.wasSent &&
      appointmentsInRange.length != 0
    ) {
      dispatch(openAppointmentsGrid(form));
    } else {
      const filesAux = [];
      files.forEach((e) =>
        filesAux.push({
          ...e,
          nombre: e.filename || e.nombre,
          base64: e.value,
        }),
      );
      const model = {
        ...form,
        numeroSiniestroPrestador: idSiniestro,
        adjuntos: filesAux,
        turno: null,
        fechaAtencion: convertDateTimeToNet(form.fechaAtencion),
        fechaHoraNuevoControl: convertDateTimeToNet(form.fechaHoraNuevoControl),
        fechaProbableDeAlta: convertDateTimeToNet(form.fechaProbableDeAlta),
        fechaProrrogaAlta: convertDateTimeToNet(form.fechaProrrogaAlta),
      };
      if (appointment.fechaHora !== null) {
        model.turno = {
          ...appointment,
          fechaHora: convertDateTimeToNet(appointment.fechaHora),
          traslado: appointment.traslado ?? false,
        };
      }
      dispatch(postForm("iet", model, idArt))
        .then((res) => {
          appointment.idTurnoSiniestro = res.value?.data?.idTurnosArray?.[0];
          dispatch(getFormProfessionals(idArt));
        })
        .then(() => {
          if (postFormCallback) {
            postFormCallback(form);
          }
          handleClose(true, false);
        })
        .catch((e) => {
          handleClose(true, true);
        })
        .finally(() => {
          // Este codigo es para abrir fpt si se hizo un turno nuevo con traslado
          const fptDoc = documentation.data.find((f) => f.formulario === "Fpt");
          if (
            appointment.traslado &&
            new Date(appointment.fechaHora) >= new Date() &&
            !appointment.fromDB &&
            fptDoc
              ? true
              : false
          ) {
            const especialidad = professionalSpecialties.data.find(
              (e) => e.id == appointment.especialidad,
            );

            var appointmentAux = {
              professionalName: appointment.profesionalApellidoNombre,
              specialityName: especialidad.nombre,
              appointmentDates: [appointment.fechaHora],
              appointmentsIds: [appointment.idTurnoSiniestro],
            };
            dispatch(
              openNewForm(
                "Fpt",
                "Formulario de Pedido de Traslado",
                appointmentAux,
              ),
            );
          }
          dispatch(clearAppointmentAssist());
        });
    }
  };

  useEffect(() => {
    if (appointmentsPostForm.wasSent) {
      handleSubmit(appointmentsPostForm.data);
    }
  }, [appointmentsPostForm.wasSent]);

  const handleAppointmentSubmit = (appointment) => {
    setAppointment(appointment);
    setOpenAppointment(false);
  };

  const closeIet = () => {
    if (isView) {
      handleClose();
    } else {
      handleOpenCancelDialog();
    }
  };

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className={classes.formLoadingContainer}>
          <CircularLoading />
        </div>
      ) : (
        <Iet
          handleClose={closeIet}
          files={files}
          setFiles={setFiles}
          handleSubmit={handleSubmit}
          model={ietModel}
          formValues={formValues}
          selectedDocument={selectedDocument}
          openAppointment={openAppointment}
          handleAppointmentSubmit={handleAppointmentSubmit}
          handleCloseAppointment={() => setOpenAppointment(false)}
          handleOpenAppointment={() => setOpenAppointment(true)}
          appointmentModel={appointment}
          isView={isView}
          disabled={disabledForm}
          idArt={idArt}
        />
      )}
    </div>
  );
};

export default IetContainer;
