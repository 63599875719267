import { MenuItem, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikActions from "../../../commons/formik/formikActions";
import FormikSelect from "../../../commons/formik/formikSelect";
import FormikTextField from "../../../commons/formik/formikTextField";

const validation = (configuration) =>
  Yup.object({
    tipo: Yup.lazy((value) => {
      if (configuration.typifyAttachmentFile) {
        return Yup.number().required("Campo obligatorio");
      } else {
        return Yup.number();
      }
    }),
    observaciones: Yup.lazy((value) => {
      if (
        configuration.requiresAttachmentObservation &&
        configuration.showAttachmentObservation
      ) {
        return Yup.string().required("Campo obligatorio").nullable();
      } else {
        return Yup.string().nullable();
      }
    }),
  });

const FileTypeDialog = ({
  file,
  open,
  handleClose,
  handleSubmit,
  configuration,
  isEditingFile,
  deleteLastFile,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={true}>
      <DialogTitle>Tipificar adjunto</DialogTitle>
      <Formik
        initialValues={file}
        validationSchema={() => validation(configuration)}
        onSubmit={(values) => {
          handleSubmit({
            ...values,
            filename: file.nombre ?? file.name ?? file.filename,
          });
        }}>
        <Form>
          <DialogContent>
            {/* cuando se está editando el formulario, tenemos disponible */}
            {/* file.filename. Cuando estamos cargando el formulario desde */}
            {/* el back, tenemos disponible file.nombre */}
            {/* Cuando tenemos un archivo seleccionado desde el explorador */}
            {/* de archivos por el dropzone, tenemos file.name */}
            <Typography>{file.nombre ?? file.name ?? file.filename}</Typography>
            {configuration.typifyAttachmentFile && (
              <FormikSelect name="tipo" value={file.tipo} label="Tipo">
                {configuration.typifyAttachmentFile.map((t) => (
                  <MenuItem value={t.tipoAdjunto} key={t.tipoAdjunto}>
                    {t.nombre}
                  </MenuItem>
                ))}
              </FormikSelect>
            )}
            {configuration.showAttachmentObservation && (
              <FormikTextField
                margin="dense"
                id="observaciones"
                name="observaciones"
                label="Observaciones"
                type="text"
                multiline
                fullWidth
              />
            )}
          </DialogContent>
          <DialogActions>
            <FormikActions
              handleCancel={() => {
                handleClose();
                if (!isEditingFile) {
                  deleteLastFile();
                }
              }}
            />
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default FileTypeDialog;
